const workTime = ['полный день', 'не полный день']
const rejectionTypes = {
  candidate: 'REJECTED_BY_CANDIDATE',
  department: 'REJECTED_BY_DEPARTMENT',
  recruiter: 'REJECTED_BY_RECRUITER',
  hm: 'REJECTED_BY_HIRING_MANAGER'
}
const statusesValues = {
  rookie: 'Rookie',
  interviewScheduled: 'InterviewScheduled',
  cameForAnInterview: 'CameForAnInterview',
  notCameForAnInterview: 'NotCameForAnInterview',
  sentToMedicalTest: 'SentToMedicalTest',
  passedMedicalTest: 'PassedMedicalTest',
  readyToWork: 'ReadyToWork',
  workStarted: 'WorkStarted',
  rejectedByDepartment: 'RejectedByDepartment',
  rejectedByRecruiter: 'RejectedByRecruiter',
  rejectedByCandidate: 'RejectedByCandidate',
  inReserve: 'InReserve',
  preInterview: 'PreInterview',
  sentToSecurityCheck: 'SentToSecurityCheck',
  sentToInternship: 'SentToInternship',
  cameForAnInternship: 'CameForAnInternship',
  notCameForAnInternship: 'NotCameForAnInternship',
  passedInternship: 'PassedInternship',
  passedSecurityCheck: 'PassedSecurityCheck',
  duplicate: 'Duplicate',
  // статусы БК
  toCallTheRobot: 'ToCallTheRobot',
  attemptsToCallEnded: 'AttemptsToCallEnded',
  phoneNumberDoesNotExist: 'PhoneNumberDoesNotExist',
  irrelevant: 'Irrelevant',
  failToReach: 'FailToReach',
  // не используемые статусы
  rejectedByHM: 'RejectedByHM',
  problemsWithRobot: 'ProblemsWithRobot',
  needToCallBack: 'NeedToCallBack',
  callToQRCode: 'CallToQRCode',
  hmInterview: 'HMInterview',
  cameToHRInterview: 'CameToHRInterview',
  cameToHMInterview: 'CameToHMInterview',
  notCameToHMInterview: 'NotCameToHMInterview',
  headOfHMInterview: 'HeadOfHMInterview',
  cameToHeadOfHM: 'CameToHeadOfHM',
  notCameToHeadOfHM: 'NotCameToHeadOfHM',
  newAppointmentApproval: 'NewAppointmentApproval',
  jobOfferSubmitted: 'JobOfferSubmitted',
  jobOfferSigned: 'JobOfferSigned',
  cameToHRBPInterview: 'CameToHRBPInterview',
  cameToOD: 'CameToOD',
  waitingForCheckup: 'WaitingForCheckup',
  checkupDocIssued: 'CheckupDocIssued',
  // статусы БК АУП
  longList: 'LongList',
  phoneInterview: 'PhoneInterview',
  hrInterview: 'HRInterview',
  notCameToHRInterview: 'NotCameToHRInterview',
  hrBPReview: 'HRBPReview',
  reviewRejectedByHRBP: 'ReviewRejectedByHRBP',
  candidateReviewApproved: 'CandidateReviewApproved',
  hrBPInterview: 'HRBPInterview',
  notCameToHRBPInterview: 'NotCameToHRBPInterview',
  odInterview: 'ODInterview',
  notCameToODInterview: 'NotCameToODInterview',
  candidateApproved: 'CandidateApproved',
  rejectedByHRBP: 'RejectedByHRBP',
  sentForEmployment: 'SentForEmployment',
  rejectedByOD: 'RejectedByOD'
}

const planStatuses = {
  new: 'New',
  approved: 'Approved',
  rejected: 'Rejected',
  updatedBySystem: 'UpdatedBySystem',
  changedByHiringManager: 'ChangedByHiringManager'
}

const departmentStatusesKeys = {
  opened: 'opened',
  closed: 'closed',
  warning: 'warning',
  suspended: 'suspended',
  notOpened: 'notOpened'
}

const needsVacanciesValues = {
  crewman: 'crewman',
  cook: 'cook',
  cashier: 'cashier',
  director: 'director',
  trainer: 'trainer',
  deputyDirector: 'deputyDirector',
  shiftManager: 'shiftManager',
  dayCleaner: 'dayCleaner',
  nightCleaner: 'nightCleaner',
  nochnoyUborshchik: 'nochnoy-uborshchik',
  driverCourier: 'driverCourier'
}

const timeNames = {
  morning: 'Утро',
  noon: 'День',
  evening: 'Вечер',
  weekend: 'Выходные'
}

const roles = {
  recruiter: 'recruiter', //не может быть в мультироли
  admin: 'admin', // центральный менеджер
  hiringManager: 'hiringManager', // директор
  manager: 'manager', // менеджер с указанием позиции
  prioritySelector: 'prioritySelector', // Установка приоритетов подбора
  // роли БК
  TU: 'TU', // ТУ
  partner: 'partner', // РМП
  operationDirector: 'operationDirector' // Операционный директор
}

const possibleActions = {
  scheduleInterview: 'scheduleInterview',
  cameForAnInterview: 'cameForAnInterview',
  notCameForAnInterview: 'notCameForAnInterview',
  sendToMedicalTest: 'sendToMedicalTest',
  sendToEmployment: 'sendToEmployment',
  passedMedicalTest: 'passedMedicalTest',
  readyToWork: 'readyToWork',
  workStarted: 'workStarted',
  rejectedByCandidate: 'rejectedByCandidate',
  rejectedByDepartment: 'rejectedByDepartment',
  rejectedByRecruiter: 'rejectedByRecruiter',
  preInterview: 'preInterview',
  sendToSecurityCheck: 'sendToSecurityCheck',
  sendToInternship: 'sendToInternship',
  cameForAnInternship: 'cameForAnInternship',
  notCameForAnInternship: 'notCameForAnInternship',
  passedInternship: 'passedInternship',
  passedSecurityCheck: 'passedSecurityCheck',
  toReserve: 'toReserve',
  markAsDuplicate: 'markAsDuplicate',
  createdByHiringManager: 'createdByHiringManager',
  toCallTheRobot: 'toCallTheRobot',
  phoneInterview: 'phoneInterview',
  hrInterview: 'hrInterview',
  rescheduleHRInterview: 'rescheduleHRInterview',
  hrBPReview: 'hrBPReview',
  reviewRejectedByHRBP: 'reviewRejectedByHRBP',
  notCameToHRInterview: 'notCameToHRInterview',
  hrBPInterview: 'hrBPInterview',
  rescheduleHRBPInterview: 'rescheduleHRBPInterview',
  approveCandidateByHRBP: 'approveCandidateByHRBP',
  approveCandidateReviewByHRBP: 'approveCandidateReviewByHRBP',
  odInterview: 'odInterview',
  rescheduleODInterview: 'rescheduleODInterview',
  notCameToHRBPInterview: 'notCameToHRBPInterview',
  approvedByOD: 'approvedByOD',
  rejectedByHRBP: 'rejectedByHRBP',
  rejectedByOD: 'rejectedByOD',
  notCameToODInterview: 'notCameToODInterview',
  checkupDocIssued: 'checkupDocIssued',
  sendForEmployment: 'sendForEmployment',
  rescheduleInterview: 'rescheduleInterview',
  changeDepartment: 'changeDepartment', // в другой ресторан
  reuse: 'reuse'
}

const modules = {
  crewman: 'crewman',
  amp: 'amp'
  // office: 'office'
}

/**
 * Коды также используются в public/extensionButton/content_script.js, там нельзя экспортить/импортить
 * там используются в виде magic number
 */
export const externalButtonCodes = {
  readySolvoproExternalButton: 'ready-solvopro-external-button',
  closeSolvoproExternalButton: 'close-solvopro-external-button',
  errorSolvoproExternalButton: 'error-solvopro-external-button',
  errorRejectSolvoproExternalButton: 'error-reject-solvopro-external-button',
  rejectSolvoproExternalButton: 'reject-solvopro-external-button',
  duplicateSolvoproExternalButton: 'duplicate-solvopro-external-button',
  addSolvoproExternalButton: 'add-solvopro-external-button'
}
export const langCases = {
  nominative: 'nominative', //именительный
  genitive: 'genitive', //родительный
  dative: 'dative', //дательный
  grand: 'grand', //винительный
  instrumental: 'instrumental', //творительный
  prepositional: 'prepositional' //предложный
}

export const appConfig = {
  formats: {
    shortDate: 'DD.MM.YYYY',
    shortDateFns: 'dd.MM.yyyy',
    fullMonthDate: 'DD MMMM YYYY',
    fullMonthDateFns: 'dd MMMM yyyy',
    shortTime: 'HH:mm',
    shortTimeWithSeconds: 'HH:mm:ss',
    shortDateAndTime: 'DD.MM.YYYY HH:mm',
    shortDateAndTimeFns: 'dd.MM.yyyy HH:mm',
    dateAndTime: 'DD.MM.YYYY в HH:mm',
    dateAndTimeFns: 'dd.MM.yyyy в HH:mm',
    shortDateApi: 'YYYY-MM-DD',
    shortDateApiFns: 'yyyy-MM-dd',
    shortDateCheckupApi: 'YYYY.MM.DD',
    shortDateAndTimeApi: 'YYYY-MM-DDTHH:mm:ss',
    shortDateAndTimeApiFns: "yyyy-MM-dd'T'HH:mm:ss",
    excelShortDate: 'dd.mm.yyyy;@',
    excelShortTime: 'HH:mm;@'
  },
  interview: {
    defaultStartTime: '06:00',
    defaultEndTime: '22:00'
  },
  roles,
  systemRole: 'system',
  rolesLabels: {
    [roles.recruiter]: 'Рекрутер',
    [roles.admin]: 'Центральный менеджер',
    [roles.hiringManager]: 'Директор',
    [roles.manager]: 'Менеджер',
    [roles.prioritySelector]: 'Установка приоритетов подбора',
    [roles.TU]: 'ТУ', //'Территориальный управляющий',
    [roles.partner]: 'РМП', //'HR Бизнес партнер',
    [roles.operationDirector]: 'Операционный директор'
  },
  planStatuses,
  planFactStatuses: {
    [planStatuses.new]: 'Новый',
    [planStatuses.approved]: 'Утверждено',
    [planStatuses.rejected]: 'Отклонено',
    [planStatuses.updatedBySystem]: 'Обновлено системой',
    [planStatuses.changedByHiringManager]: 'На согласовании'
  },
  schedulerWeekNavigate: {
    today: 'Сегодня',
    previous: 'Предыдущая',
    next: 'Следующая'
  },
  preferences: {
    rates: workTime,
    times: Object.values(timeNames).map(name => name.toLowerCase())
  },
  statuses: {
    values: statusesValues
  },
  noAuthActions: [],
  workflows: {
    steps: {
      interviews: {
        departmentsListWithPlans: 'DEPARTMENTS_LIST_WITH_PLANS',
        schedulerCustom: 'SCHEDULER_CUSTOM',
        adminScheduler: 'ADMIN_SCHEDULER',
        selectDepartmentForAction: 'SELECT_DEPARTMENT_FOR_ACTION'
      },
      rejectionTypes,
      calls: {
        later: 'CALL_LATER'
      },
      modals: {
        utm: 'UTM_MODAL'
      },
      other: {
        showPanel: 'SHOW_PANEL'
      }
    },
    candidate: {
      prefixUrl: 'flow/candidate/',
      actions: possibleActions,
      name: 'candidate'
    },
    amp: {
      name: 'amp'
    },
    plan: {
      name: 'plan'
    },
    call: {
      bkPrefixUrl: 'bk/crewman/', // TODO: возможно 'crewman' надо брать из вакансии
      prefixUrl: 'flow/candidate/',
      actions: {
        later: 'callLater', // перезвонить позже
        missed: 'callMissed' // не дозвонился
      },
      state: {
        later: 'later', // перезвонить позже
        missed: 'missed' // не дозвонился
      }
    },
    sla: [
      { name: 'От создания статуса', key: 'statusCreated' },
      { name: 'От окончания интервью', key: 'interviewEnded' }
    ]
  },
  references: {
    citizenships: 'citizenships',
    regions: 'regions',
    divisions: 'divisions',
    utms: 'utm?requestType=',
    requestTypes: 'requestTypes',
    sources: 'utm/sources',
    mediums: 'utm/mediums',
    inboxStates: 'inboxStates',
    cities: 'cities',
    positions: 'positions'
  },
  utmTypes: {
    requestType: 'requestType',
    utmSource: 'utmSource',
    utmMedium: 'utmMedium'
  },
  interviewHours: {
    start: 9,
    end: 22
  },
  settings: {
    sms: 'SMS',
    interviewScheduler: {
      start: 'INTERVIEW_SCHEDULE_HOUR_START',
      end: 'INTERVIEW_SCHEDULE_HOUR_END'
    },
    rejectionTypes,
    departmentType: 'DEPARTMENT_TYPE',
    departmentTypes: 'DEPARTMENT_TYPES',
    seedPopup: 'SEED_POPUP',
    sortableStates: 'SORTABLE_STATES',
    language: 'LANGUAGE',
    maxDistance: 'MAX_DISTANCE',
    globalSchedule: 'GLOBAL_SCHEDULE',
    globalWeekend: 'GLOBAL_WEEKEND',
    planningStartDay: 'PLANNING_START_DAY'
  },
  nextPlanningAvailableDate: 25,
  needsVacancies: needsVacanciesValues,
  timeNames,
  userFormModes: {
    create: 'create',
    edit: 'edit'
  },
  reportSourceTypes: {
    main: 'main',
    workSchedule: 'workSchedule'
  },
  // На инбоксе будут использоваться фильтры, всевозможные значения которых будут получаться из запроса.
  inboxFilters: {
    requestType: 'requestType',
    utmSource: 'utm_source'
  },
  requestTypeCareerSite: 'Отклик из карьерного сайта',
  departmentStatusesKeys,
  departmentStatuses: [
    {
      key: departmentStatusesKeys.opened,
      value: 'Активен',
      actionValue: 'Активен',
      color: 'success'
    },
    {
      key: departmentStatusesKeys.closed,
      value: 'Неактивен',
      actionValue: 'Неактивен',
      color: 'danger'
    },
    {
      key: departmentStatusesKeys.suspended,
      value: 'Приостановлен',
      actionValue: 'Приостановлен',
      color: 'warning'
    },
    {
      key: departmentStatusesKeys.notOpened,
      value: 'Еще не открыт',
      actionValue: 'Еще не открыт',
      color: 'warning'
    }
  ],
  departmentWarningStatus: {
    key: departmentStatusesKeys.warning,
    value: 'Требует внимания',
    actionValue: 'Требует внимания',
    color: 'warning'
  },
  usersStatuses: [
    {
      key: 'activate',
      actionValue: 'Активировать',
      value: false
    },
    {
      key: 'deactivate',
      actionValue: 'Деактивировать',
      value: true
    }
  ],
  features: {
    analytics: 'ANALYTICS',
    testData: 'TEST_DATA',
    orgLayers: 'ORG_LAYERS',
    agencies: 'AGENCIES',
    workflowDesigner: 'WORKFLOW_DESIGNER',
    vacancyEditor: 'VACANCY_EDITOR',
    sms: 'SMS',
    amp: 'AMP',
    jobSites: 'JOBSITES',
    telegram: 'TELEGRAM',
    hrm: 'HRMESSENGER'
  },
  filterPeriods: {
    lastWeek: 'lastWeek',
    currentMonth: 'currentMonth',
    lastMonth: 'lastMonth',
    quarter: 'quarter',
    custom: 'custom'
  },
  integrationSites: {
    hrm: 'hrm'
  },
  integrationStatuses: {
    none: 'none',
    actionRequired: 'actionRequired',
    tokenRequired: 'tokenRequired',
    online: 'online',
    error: 'error'
  },
  externalButtonCodes,
  modules,
  modulesLabels: {
    [modules.crewman]: 'Массподбор',
    [modules.amp]: 'АУП'
    // [modules.office]: 'ОФИС'
  },
  chat: {
    direction: {
      incoming: 'INCOMING',
      outgoing: 'OUTGOING'
    }
  }
}
