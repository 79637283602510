import React from 'react'
import { Button, Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { getValueByDotNotationKey } from '../../../../helpers'

let searchInput
let searchText = {}
let searchedColumns = []
export const handleSearch = (selectedKeys, confirm, dataIndex) => {
  searchText[dataIndex] = selectedKeys[0]
  searchedColumns = [...new Set([...searchedColumns, dataIndex])]
  confirm?.()
}

export const handleReset = (clearFilters, confirm, dataIndex) => {
  if (dataIndex) {
    delete searchText[dataIndex]
  } else {
    searchText = {}
  }
  searchedColumns = dataIndex ? [...searchedColumns.filter(e => e !== dataIndex)] : []
  clearFilters?.()
  confirm?.()
}

const getColumnSearchProps = (dataIndex, placeholder = '', render) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node
        }}
        placeholder={'Поиск ' + placeholder}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          width: 208,
          marginBottom: 8,
          display: 'block'
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 100 }}
        >
          Фильтр
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm, dataIndex)}
          size="small"
          style={{ width: 100 }}
        >
          Очистить
        </Button>
        {/*<Button*/}
        {/*  type="link"*/}
        {/*  size="small"*/}
        {/*  onClick={() => {*/}
        {/*    confirm({*/}
        {/*      closeDropdown: false*/}
        {/*    })*/}
        {/*    setSearchText(selectedKeys[0])*/}
        {/*    setSearchedColumn(dataIndex)*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Filter*/}
        {/*</Button>*/}
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownOpenChange: visible => {
    if (visible) {
      setTimeout(() => searchInput?.select(), 100)
    }
  },
  onFilter: (value, record) => {
    let recordValue = getValueByDotNotationKey(dataIndex, record)

    return typeof recordValue === 'string'
      ? recordValue?.toString()?.toLowerCase()?.includes(value.toLowerCase())
      : recordValue?.name?.toString()?.toLowerCase()?.includes(value.toLowerCase()) ||
          recordValue?.email?.toString()?.toLowerCase()?.includes(value.toLowerCase())
  },
  render: (text, item) => {
    if (render)
      return render({
        text,
        item,
        isInclude: searchedColumns.includes(dataIndex),
        searchText: searchText?.[dataIndex]
      })

    return searchedColumns.includes(dataIndex) ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0
        }}
        searchWords={[searchText?.[dataIndex]]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )
  },
  reset: handleReset
})
export default getColumnSearchProps
