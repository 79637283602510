import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Col, Form, Row, Spin, Typography } from 'antd'
import ExtraBlock from './ExtraBlock'
import { UserContext } from '../../../../contexts/userContext'
import { convertObjectToArray, getObjectValueByPath } from '../../../../helpers'
import { convertCrewmenToObject } from '../../../../helpers/plan'
import ManagerButtons from './ManagerButtons'
import RequestNeedTable from './RequestNeedTable'
import { appConfig } from '../../../../constants/appConfig'

const { Text } = Typography

function PlanningPersonal({
  planForRole,
  onSaveMutate,
  isLoading,
  isNewPlan,
  activePeriod,
  plan,
  departmentForNewPlanId = null,
  departmentId
}) {
  const { user, isRecruiter } = useContext(UserContext)
  const [year, month] = (activePeriod?.id || '').split('/')
  const [wasFormEdited, setWasFormEdited] = useState(false)
  const [form] = Form.useForm()

  /**
   * Если текущий пользователь не может редактировать, блокируем поля. Прошлый период редактировать нельзя вообще
   */
  // const isEditableByUser = useCallback(
  //   plan => {
  //     if (isManager || isAdmin) {
  //       return !isRejected(plan)
  //     }
  //     return isHiringManager
  //   },
  //   [isManager, isAdmin, isHiringManager]
  // )
  // const readOnly = useMemo(
  //   () => !isEditableByUser(planForRole) || activePeriod?.isPast,
  //   [activePeriod?.isPast, planForRole, isEditableByUser]
  // )
  const readOnly = useMemo(
    () => !(planForRole?.possibleActions?.length || isNewPlan),
    [planForRole?.possibleActions, isNewPlan]
  )

  const saveWithCallback = useCallback(
    ({ values: formValues, cb } = {}) => {
      const values = formValues || form.getFieldsValue()
      Object.keys(values.crewmen).forEach(key => {
        values.crewmen[key] = {
          ...values.crewmen[key],
          partTime: values.crewmen[key]?.partTime || undefined,
          fullTime: values.crewmen[key]?.fullTime || undefined,
          vacancy: key,
          citizenship: [].concat(values.crewmen[key].citizenship?.cis ? ['cis'] : [])
        }
      })

      onSaveMutate(
        {
          ...values,
          ...vacanciesToArray(values),
          departmentId: planForRole?.department?._id || user?.department?._id,
          // из бэка возвращается обьект при выборе в селекторе выставляется в значение только ID
          interviewerDepartment:
            values?.interviewerDepartment?._id || values?.interviewerDepartment || null,
          month: +(month || planForRole?.month),
          year: +(year || planForRole?.year),
          planId: planForRole?._id,
          ...(departmentId && { departmentId })
        },
        {
          onError: () => {
            setWasFormEdited(true)
          },
          onSuccess: () => {
            setWasFormEdited(false)
            cb?.()
          }
        }
      )
    },
    [departmentId, form, month, onSaveMutate, planForRole, user?.department?._id, year]
  )

  const n = field => (field === Infinity ? 0 : field || 0)
  /**
   * Если в данных плана в блоке updatedBySystem отсутствует какое-либо поле, нужное для расчётов, разблокируем его
   */
  const fieldIsEditable = useCallback(
    field => {
      return getObjectValueByPath(planForRole?.updatedBySystem, field) === undefined
    },
    [planForRole]
  )

  /**
   * Получение поля для расчётов.
   * Если план новый (данные нового плана копируются из предыдущего месяца в блок filledByDirector.
   * План считается новым, пока его не сохранил директор),
   * либо поле отсутствует в блоке updatedBySystem, берём значение из блока filledByDirector
   */
  const getFieldName = useCallback(
    field => {
      return isNewPlan || fieldIsEditable(field) ? 'filledByDirector' : 'updatedBySystem'
    },
    [isNewPlan, fieldIsEditable]
  )

  const calculateEstimate = useCallback(
    data => {
      const ratesFullTime = data?.rates?.fullTime || {}
      const ratesPartTime = data?.rates?.partTime || {}
      const averageFullTime =
        data?.[getFieldName(['averageOutput', 'fullTime'])]?.averageOutput?.fullTime
      const averagePartTime =
        data?.[getFieldName(['averageOutput', 'partTime'])]?.averageOutput?.partTime
      const tradeTurnover = n(data?.[getFieldName('tradeTurnover')]?.tradeTurnover)
      const spmh = n(data?.[getFieldName('spmh')]?.spmh)
      const estimate = data?.estimate || {}

      const planWorkingHours = Math.round(tradeTurnover / spmh)
      const planFullTime = Math.round(
        (planWorkingHours - n(estimate.planPartTime) * n(averagePartTime)) / n(averageFullTime)
      )
      const factWorkingHours = Math.round(
        n(ratesFullTime.employees) * n(averageFullTime) -
          n(ratesFullTime.dismiss) * n(averageFullTime) -
          n(ratesFullTime.training) * n(averageFullTime) +
          (n(ratesPartTime.employees) * n(averagePartTime) -
            n(ratesPartTime.dismiss) * n(averagePartTime) -
            n(ratesPartTime.training) * n(averagePartTime))
      )

      const workingHoursPercent = Math.round((factWorkingHours / planWorkingHours) * 100)
      const staffFullnessPercent = Math.round(
        ((n(ratesFullTime.employees) -
          n(ratesFullTime.dismiss) -
          n(ratesFullTime.training) +
          (n(ratesPartTime.employees) - n(ratesPartTime.dismiss) - n(ratesPartTime.training))) /
          (n(planFullTime) + n(estimate.planPartTime))) *
          100
      )
      const demandFullTime = Math.round(
        n(planFullTime) -
          n(ratesFullTime.employees) +
          n(ratesFullTime.dismiss) +
          n(ratesFullTime.training)
      )
      const demandPartTime = Math.round(
        n(estimate.planPartTime) -
          n(ratesPartTime.employees) +
          n(ratesPartTime.dismiss) +
          n(ratesPartTime.training)
      )

      return {
        ...data,
        estimate: {
          ...estimate,
          planWorkingHours, // Плановое количество часов линейки
          planFullTime, // План по ФТ
          factWorkingHours, // Фактическое количество часов
          workingHoursPercent, // Выработка часов линейки
          staffFullnessPercent, // Заполненность штата
          demandFullTime, // Потребность по ФТ
          demandPartTime // Потребность по ПТ
        }
      }
    },
    [getFieldName]
  )

  const vacanciesToArray = data => ({
    ...data,
    crewmen: [...convertObjectToArray(data.crewmen)]
  })

  const editFormData = useCallback(
    (changedFields, values) => {
      // let wasEdit = false
      Object.keys(values.crewmen).forEach(key => {
        // if (values.crewmen[key]?.partTime || values.crewmen[key]?.fullTime) {
        //   wasEdit = true
        // }
        values.crewmen[key] = {
          ...values.crewmen[key],
          partTime:
            (changedFields?.crewmen?.[key] && 'partTime' in changedFields.crewmen[key]
              ? changedFields.crewmen[key]?.partTime
              : values.crewmen[key]?.partTime) || 0,
          fullTime:
            (changedFields?.crewmen?.[key] && 'fullTime' in changedFields.crewmen[key]
              ? changedFields.crewmen[key]?.fullTime
              : values.crewmen[key]?.fullTime) || 0
        }
      })
      form.setFieldsValue(calculateEstimate(values))
      setWasFormEdited(true)
    },
    [form, calculateEstimate]
  )

  /**
   * setting initialValues with async query
   */
  useEffect(() => {
    if (Object.keys(planForRole || {}).length > 0) {
      !form.isFieldsTouched() &&
        form.setFieldsValue(calculateEstimate(convertCrewmenToObject(planForRole)))
    } else {
      form.resetFields()
    }
  }, [form, planForRole, calculateEstimate])

  const inactiveVacancies = useMemo(
    () =>
      planForRole?.crewmen?.filter(c => !c.vacancy.active).map(c => c.vacancy.displayName) || [],
    [planForRole?.crewmen]
  )

  return (
    <Spin spinning={isLoading} size="large">
      <Form
        form={form}
        className="planningPersonal mt-3"
        onValuesChange={editFormData}
        colon={false}
      >
        {/* TODO: скрыть на стейдж
        <CalculationNeedBlock readOnly={readOnly} isNewPlan={isNewPlan} fieldIsEditable={fieldIsEditable}/>
        <CalculationsBlock readOnly={readOnly}/>
        */}
        <div className="request-need-wrapper">
          <RequestNeedTable
            readOnly={readOnly}
            data={planForRole?.crewmen}
            isDepartmentPlan={plan?.current?.state === appConfig.planStatuses.approved}
          />
        </div>

        <ExtraBlock
          plan={plan}
          form={form}
          interviewerDepartment={planForRole?.interviewerDepartment}
          departmentForNewPlanId={departmentForNewPlanId}
          onEdit={setWasFormEdited}
          readOnly={readOnly}
        />

        {isRecruiter &&
          inactiveVacancies?.length > 0 &&
          plan?.current?.state !== appConfig.planStatuses.approved &&
          plan?.current?.state !== appConfig.planStatuses.rejected && (
            <Row>
              <Col span={20}>
                <Text type="danger">
                  Обращаем Ваше внимание, что следующие вакансии стали неактивными и не будут
                  сохранены в текущий план: <b>{inactiveVacancies.join(', ')}</b>
                </Text>
              </Col>
            </Row>
          )}

        <ManagerButtons
          plan={planForRole}
          planForm={form}
          needSave={wasFormEdited}
          onSave={saveWithCallback}
          isNewPlan={isNewPlan}
          isLoading={isLoading}
          wasFormEdited={wasFormEdited}
        />
      </Form>
    </Spin>
  )
}

export default PlanningPersonal
