import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactExport from 'react-data-export'
import { FileExcelOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { excelColumnsShape } from '../../../../constants/propTypesShapes'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

/**
 * Example
 * const multiDataSet = [
     {
        columns: [
          { title: 'Headings', width: { wpx: 80 } }, // pixels width
          { title: 'Text Style', width: { wch: 40 } }, // char width
          { title: 'Colors', width: { wpx: 90 } }
        ],
        data: [
          [
            { value: 'H1', style: { font: { sz: '24', bold: true } } },
            { value: 'Bold', style: { font: { bold: true } } },
            { value: 'Red', style: { fill: { patternType: 'solid', fgColor: { rgb: 'FFFF0000' } } } }
          ],
          [
            { value: 'H2', style: { font: { sz: '18', bold: true } } },
            { value: 'underline', style: { font: { underline: true } } },
            { value: 'Blue', style: { fill: { patternType: 'solid', fgColor: { rgb: 'FF0000FF' } } } }
          ],
        ]
    }
  ]
 */

export default function ExcelData({
  dataColumns,
  dataSource,
  defaultColWidth,
  colWidthAddPx,
  filename,
  fileExt,
  title,
  isLoading,
  isDownloadExcelDisabled
}) {
  const getColWidth = useCallback(
    value => (value ? value + colWidthAddPx : defaultColWidth),
    [colWidthAddPx, defaultColWidth]
  )
  const getCellValue = useCallback(
    value => (value === null || value === undefined ? '' : value),
    []
  )

  const columns = useMemo(() => {
    return dataColumns?.reduce((all, current) => {
      all.push({
        title: current.excelData?.title || current.title,
        width: { wpx: getColWidth(current.width) },
        style: { alignment: { wrapText: true }, font: { bold: true } }
      })
      return all
    }, [])
  }, [getColWidth, dataColumns])

  const data = useMemo(() => {
    /**
     * Когда нет данных для отчета. Нужно все равно отрисовать все колонки.
     * Для этого необходимо создать хотябы одну строку отчета с полностью пустыми значениями
     */
    let dataList = dataSource
    let isEmptyData = false
    if (!dataSource?.length) {
      dataList = [{}]
      isEmptyData = true
    }
    return (
      dataList?.reduce((prev, current) => {
        const line = dataColumns?.map(col => {
          const render = col?.excelData?.render || col?.render
          const cellStyle = col?.excelData?.getStyle ? col.excelData.getStyle(current) : {}
          return {
            value: isEmptyData ? '' : getCellValue(render(current)),
            style: { alignment: { horizontal: 'left' }, ...(cellStyle || {}) }
          }
        })
        prev.push(line)
        return prev
      }, []) || []
    )
  }, [dataColumns, dataSource, getCellValue])

  const multiDataSet = useMemo(
    () => [
      {
        columns,
        data
      }
    ],
    [columns, data]
  )

  return (
    <ExcelFile
      element={
        <Button disabled={isDownloadExcelDisabled} loading={isLoading}>
          <FileExcelOutlined />
          {title}
        </Button>
      }
      filename={filename}
      fileExtension={fileExt}
    >
      <ExcelSheet dataSet={multiDataSet} name="Лист 1" />
    </ExcelFile>
  )
}

ExcelData.defaultProps = {
  defaultColWidth: 50,
  colWidthAddPx: 30,
  filename: 'Лист',
  fileExt: 'xlsx',
  title: 'Скачать',
  loading: false
}

ExcelData.propTypes = {
  dataColumns: PropTypes.arrayOf(excelColumnsShape).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultColWidth: PropTypes.number,
  colWidthAddPx: PropTypes.number,
  filename: PropTypes.string.isRequired,
  fileExt: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isDownloadExcelDisabled: PropTypes.bool
}
