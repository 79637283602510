import { useMutation } from 'react-query'

import { api } from './api'
import { USERS_KEY } from './users'

const fetchLogin = ({ login, password }) => {
  return api.post({ path: `${USERS_KEY}/signin`, data: { login, password } })
}

const fetchLogout = () => {
  return api.post({ path: `${USERS_KEY}/signout` })
}

export function useMutateLogin(queryClient) {
  return useMutation(fetchLogin, {
    onMutate: () => queryClient.clear()
  })
}

// TODO: для входа без авторизации - надо будет переделать
const getToken = () => localStorage.getItem('token') || ''
const setToken = token => localStorage.setItem('token', token)
const removeToken = () => localStorage.removeItem('token')

const logout = queryClient => () => {
  queryClient?.clear?.()
  removeToken()
  // localStorage.clear()
  // Sentry.configureScope(scope => scope.setUser(null))
}

export function useMutateLogout(queryClient) {
  return useMutation(fetchLogout, {
    onSuccess: logout(queryClient)
  })
}

export const auth = {
  logout,
  getToken,
  setToken,
  removeToken
}
