import React, { useCallback } from 'react'
import { Typography, Row, Col } from 'antd'
import { FrownOutlined } from '@ant-design/icons'
import { useQueryClient } from 'react-query'

import { auth } from '../../../../api/auth'

import './ErrorLayout.less'

const { Title, Link, Text } = Typography

export const ErrorLayout = ({ error, componentStack, ...rest }) => {
  const queryClient = useQueryClient()
  const handleLogout = useCallback(() => {
    auth.logout(queryClient)
    window.location.replace('/')
  }, [queryClient])

  console.log('%c error', 'color: red; font-weight: bold; font-size: 22px', {
    error,
    componentStack,
    rest
  })
  return (
    <div className="error-layout">
      <div className="error-content">
        <Row justify="center">
          <FrownOutlined className="error-icon" />
        </Row>
        <Row className="mb-3" justify="center">
          <Title level={2}>Произошла ошибка</Title>
        </Row>
        <Row justify="center">
          <Col xs={12}>
            <Row justify="center">
              <Link onClick={handleLogout}>Перезагрузить</Link>
            </Row>
          </Col>
        </Row>
      </div>
      {process.env.NODE_ENV === 'development' && (error?.message || componentStack) && (
        <Row justify="center">
          <Text className="error-scroll">
            {error?.message} {componentStack}
          </Text>
        </Row>
      )}
    </div>
  )
}
