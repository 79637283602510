import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Button, Col, DatePicker, Form, Input, Row, Select, Tabs, Typography } from 'antd'
import dayjs from 'dayjs'
import { useRecoilState } from 'recoil'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  startOfDay,
  format
} from 'date-fns'

import { appConfig } from '../../../../constants/appConfig'
import { CandidateItem } from '../'
import {
  getFilterDrawerCount,
  selectSearchFilter,
  sortBy,
  groupBy,
  sortByLastHistory,
  removeSpecSymbols,
  debounce
} from '../../../../helpers'
import { hiringManagerSettingsAtom, findSettingsAtom } from '../../../../recoil/atoms'
import { useConfigContextData } from '../../../../hooks/useConfigContextData'
import { filterPeriods } from '../../../../constants/filterPeriods'
import { colSettings, DEFAULT_PERIODS, localStorageName } from '../../../../constants'
import { PhoneItem, VacanciesItem } from '../FormItems/CandidateProfileItems'
import { useVacancies } from '../../../../hooks/useVacancies'

import './CandidateGroupMenu.less'
import { parseFilterLocalStorage } from '../../Helpers'

const { Option } = Select
const { Text } = Typography
const { RangePicker } = DatePicker

const periods = DEFAULT_PERIODS
const expiredOptions = [
  { name: 'Да', value: 1 },
  { name: 'Нет', value: 0 },
  { name: 'Все', value: -1 }
]

const CandidateGroupMenu = ({
  groups,
  showProfile,
  // workflowName,
  isSearch = false, // компонент используется на странице поиска
  onChange,
  filters,
  initFilters,
  title
}) => {
  const {
    getWorkflowStateLabelsByName,
    getWorkflowStatesWithLabelsByName,
    getStateWithLabelByWorkflowAndName
  } = useConfigContextData()

  const [hiringManagerSettings, setHiringManagerSettings] =
    useRecoilState(hiringManagerSettingsAtom)
  const [findSettings, setFindSettings] = useRecoilState(findSettingsAtom)

  const [settings, setSettings] = useMemo(
    () =>
      isSearch
        ? [findSettings, setFindSettings]
        : [hiringManagerSettings, setHiringManagerSettings],
    [findSettings, setFindSettings, hiringManagerSettings, setHiringManagerSettings, isSearch]
  )

  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState(undefined)
  // const [rangeOptions, setRangeOptions] = useState({})
  const [form] = Form.useForm()
  const { vacancies } = useVacancies()

  useEffect(() => {
    if (!isSearch) {
      form.resetFields()
      let data = { ...initFilters, ...parseFilterLocalStorage(localStorageName) }
      if (form.isFieldsTouched()) {
        data = { ...initFilters, ...form.getFieldsValue() }
      }
      const { start, end } = data || periods?.find(period => period.id === data.period)?.value || {}

      // setRangeOptions({ start, end })
      setSelectedPeriod(data.period)
      form.setFieldsValue({ ...data, range: [start && dayjs(start), end && dayjs(end)] })
    }
  }, [form, initFilters, isSearch])

  const handleChangeFilter = useCallback(
    options => {
      form.setFieldsValue(options)
      onChange(options)
    },
    [onChange, form]
  )
  const handleResetAll = useCallback(() => {
    const data = {
      start: undefined,
      end: undefined,
      period: undefined,
      expired: undefined,
      name: undefined,
      phone: undefined,
      vacancies: undefined
    }
    handleChangeFilter(data)
    setSelectedPeriod(undefined)
    setIsFiltersOpen(false)
  }, [handleChangeFilter])

  // const getData = useCallback(() => {
  //   const { range, ...restValues } = form.getFieldsValue()
  //   let [start, end] = range || [rangeOptions.start, rangeOptions.end]
  //   if (!start || !end) {
  //     start = filters.start
  //     end = filters.end
  //   }
  //   return {
  //     ...restValues,
  //     start,
  //     end
  //   }
  // }, [filters, rangeOptions, form])

  const handleSelectExpired = useCallback(
    value => {
      handleChangeFilter({ expired: value })
    },
    [handleChangeFilter]
  )

  const handleChangeVacancies = useCallback(
    value => {
      handleChangeFilter({ vacancies: value?.length ? value : undefined })
    },
    [handleChangeFilter]
  )

  const handleChangePhone = useCallback(
    ({ target }) =>
      handleChangeFilter({ phone: removeSpecSymbols(target.value)?.trim()?.replaceAll(' ', '') }),
    [handleChangeFilter]
  )
  const debounceChangePhone = useMemo(() => debounce(handleChangePhone, 800), [handleChangePhone])

  const handleChangeName = useCallback(
    ({ target }) => handleChangeFilter({ name: removeSpecSymbols(target.value)?.trim() }),
    [handleChangeFilter]
  )
  const debounceChangeName = useMemo(() => debounce(handleChangeName, 800), [handleChangeName])

  const handleSelectPeriod = useCallback(
    periodId => {
      const data = { period: periodId }
      if (periodId !== filterPeriods.custom.id) {
        const { start, end } = periods?.find(period => period.id === periodId)?.value || {}
        // setRangeOptions({ start, end })
        form.setFieldsValue({ range: [dayjs(start), dayjs(end)] })
        data.start = start
        data.end = end
      }
      setSelectedPeriod(periodId)
      handleChangeFilter(data)
    },
    [form, handleChangeFilter]
  )

  const handleChangeRange = useCallback(
    (data, clearData) => {
      const [start, end] = data || clearData
      setSelectedPeriod(filterPeriods.custom.id)
      form.setFieldsValue({ period: filterPeriods.custom.id })
      // setRangeOptions({ start, end })
      handleChangeFilter({
        start: dayjs(startOfDay(new Date(start)))
          .utc()
          .format(),
        end: dayjs(endOfDay(new Date(end)))
          .utc()
          .format(),
        period: filterPeriods.custom.id
      })
    },
    [form, handleChangeFilter]
  )

  const groupsState = useMemo(
    () => groupBy(groups?.sort(sortByLastHistory()), 'state.name'),
    [groups]
  )
  // const getStatusLabel = useCallback(
  //   status =>
  //     isSearch
  //       ? getWorkflowStateLabelsByName(status?.key)
  //       : getStateWithLabelByWorkflowAndName({
  //           stateKey: status?.key,
  //           workFlowName: isAmpUser
  //             ? appConfig.workflows.amp.name
  //             : appConfig.workflows.candidate.name
  //         })?.label,
  //   [getWorkflowStateLabelsByName, getStateWithLabelByWorkflowAndName, isSearch, isAmpUser]
  // )
  const existApplicationStatusList = useMemo(() => {
    const list = getWorkflowStatesWithLabelsByName()
      ?.filter(item => groupsState[item.name]?.length > 0)
      ?.sort(sortBy('sortOrder'))
    return groupBy(list, 'displayName', '###Без названия###')
  }, [groupsState, getWorkflowStatesWithLabelsByName])

  const activeTab = useMemo(
    () => existApplicationStatusList?.[settings?.activeTab]?.[0]?.name,
    [existApplicationStatusList, settings]
  )

  // @TODO использовать из хелпера getStringDiffDates
  const getDueDateRes = useCallback(groupItem => {
    let dueDateRes = {}
    const currentState = groupItem?.history[groupItem?.history?.length - 1]
    if (currentState) {
      const now = new Date()
      const departmentDate = currentState.expireAt ? new Date(currentState.expireAt) : new Date()
      const days = differenceInDays(departmentDate, now)
      const hours = differenceInHours(departmentDate, now)
      let minutes = differenceInMinutes(departmentDate, now)

      if (hours < 24) {
        minutes = minutes - 60 * hours
      }

      dueDateRes = { msg: '', style: 'green' }

      if (days >= 1) {
        dueDateRes.msg = 'Истекает ' + format(departmentDate, appConfig.formats.shortDateFns)
      } else if (days < 1 && days >= 0 && hours < 24 && minutes > 0) {
        dueDateRes.msg = 'Истекает через ' + (hours ? hours + ' ч. ' : '') + minutes + ' мин.'
      } else if (days <= 0 && (hours < 0 || minutes < 0)) {
        dueDateRes.style = 'red'
        if (days < 0) {
          dueDateRes.msg = 'Просрочено на ' + Math.abs(days) + ' д.'
        } else {
          dueDateRes.msg = 'Просрочено на ' + Math.abs(hours) + ' ч. ' + Math.abs(minutes) + ' мин.'
        }
      }
    }
    return dueDateRes
  }, [])

  const getStatusWthDate = useCallback(
    (status, candidate) => {
      if (
        // candidate?.state?.name === appConfig.statuses.values.interviewScheduled &&
        candidate?.state?.interview
      ) {
        const statusName =
          getStateWithLabelByWorkflowAndName({
            stateKey: status?.name,
            workFlowName: candidate?.vacancy?.workflow?.name
          })?.label || status?.name
        const departmentDate = dayjs(candidate.state.interview.start)
          .utcOffset(candidate.state.interview?.timeOffset || 0)
          .format(appConfig.formats.dateAndTime)
        return `${statusName} ${departmentDate}`
      }
      return ''
    },
    [getStateWithLabelByWorkflowAndName]
  )

  const handleTabsChange = useCallback(
    value =>
      setSettings(oldValues => ({
        ...(oldValues || {}),
        activeTab: value
      })),
    [setSettings]
  )

  const tabsItems = useMemo(
    () =>
      Object.values(existApplicationStatusList || {}).map(value => {
        const count = groupsState[value?.[0]?.name]?.length || 0
        const groupList = groupsState[value?.[0]?.name] || []

        return {
          key: value?.[0]?.name,
          label: `${getWorkflowStateLabelsByName(value?.[0]?.name)} (${count})`,
          children: (
            <>
              {groupList?.map(groupItem => (
                <CandidateItem
                  key={groupItem._id}
                  candidate={groupItem}
                  dueDate={getDueDateRes(groupItem)}
                  showProfile={showProfile}
                  status={getStatusWthDate(value[0], groupItem)}
                  interview={groupItem.state.interview}
                  interviewerDepartment={groupItem.application?.interviewerDepartment}
                  department={groupItem.application?.department}
                  isSearch={isSearch}
                />
              ))}
            </>
          )
        }
      }),
    [
      getWorkflowStateLabelsByName,
      existApplicationStatusList,
      getDueDateRes,
      getStatusWthDate,
      groupsState,
      isSearch,
      showProfile
    ]
  )

  const tabsList = useMemo(() => {
    return tabsItems?.length ? (
      <Tabs
        className="custom-tabs"
        onChange={handleTabsChange}
        activeKey={activeTab}
        items={tabsItems}
      />
    ) : null
  }, [activeTab, handleTabsChange, tabsItems])

  return (
    <>
      {!isSearch && (
        <Row align="middle" justify="space-between" className="btn-container">
          {title && (
            <Col span={10}>
              <div className="block-title">{title}</div>
            </Col>
          )}
          <Col className={`filter-buttons ${isFiltersOpen && 'opened'}`}>
            <Button
              className="mr-3"
              type="link"
              size="large"
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            >
              <Badge count={getFilterDrawerCount(filters)}>
                <div className="filter-icon" />
              </Badge>
              <Text className="ml-3">Фильтры</Text>
            </Button>
            <Button
              onClick={handleResetAll}
              type="primary"
              style={{ margin: '0 8px' }}
              disabled={!getFilterDrawerCount(filters)}
            >
              Сбросить
            </Button>
          </Col>
        </Row>
      )}
      {!isSearch && isFiltersOpen && (
        <Form form={form} className="filters-container">
          <Row gutter={[12, 0]}>
            <Col span={6}>
              <Row gutter={[12, 4]} justify="start">
                <Col>Период</Col>
                <Col span={24}>
                  <Form.Item name="period" label="" labelCol={colSettings.full}>
                    <Select
                      // size="large"
                      placeholder="Период"
                      showSearch
                      optionFilterProp="children"
                      filterOption={selectSearchFilter}
                      onSelect={handleSelectPeriod}
                    >
                      {periods?.map(period => (
                        <Option key={period.id} value={period.id}>
                          {period.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={6}>
              <Row gutter={[12, 4]}>
                <Col>ФИО</Col>
                <Col span={24}>
                  <Form.Item name="name">
                    <Input
                      onChange={debounceChangeName}
                      size="middle"
                      placeholder="Введите ФИО"
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={6}>
              <Row gutter={[12, 4]} className="">
                <Col>Телефон</Col>
                <Col span={24}>
                  <PhoneItem onChange={debounceChangePhone} size="middle" label="" allowClear />
                </Col>
              </Row>
            </Col>

            <Col span={6}>
              <Row gutter={[12, 4]} className="">
                <Col>Вакансия</Col>
                <Col span={24}>
                  <VacanciesItem
                    onChange={handleChangeVacancies}
                    vacancyList={vacancies}
                    label=""
                    // size="large"
                    mode="multiple"
                    required={false}
                    name="vacancies"
                    allowClear
                  />
                </Col>
              </Row>
            </Col>
            {selectedPeriod === filterPeriods.custom.id && (
              <Col span={6}>
                <Row gutter={[12, 4]} justify="start">
                  <Col>Точные даты</Col>
                  <Col span={24}>
                    <Form.Item name="range" label="" labelCol={colSettings.full}>
                      <RangePicker
                        // size="large"
                        placeholder={['Начало', 'Конец']}
                        onChange={handleChangeRange}
                        allowClear={false} /* default value (false) sometime not works */
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={6}>
              <Row gutter={[12, 4]} className="">
                <Col>Просрочено</Col>
                <Col span={24}>
                  <Form.Item name="expired" label="" labelCol={colSettings.full}>
                    <Select
                      // size="large"
                      placeholder="Просрочено"
                      // showSearch
                      optionFilterProp="children"
                      filterOption={selectSearchFilter}
                      onSelect={handleSelectExpired}
                    >
                      {expiredOptions?.map(item => (
                        <Option key={item.name} value={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}

      {(!Object.entries(existApplicationStatusList || {})?.length ||
        (isSearch && !findSettings?.search)) && (
        <small>
          Нет результатов. {isSearch ? 'Введите' : 'Измените фильтры или введите'} полный номер
          телефона или ФИО чтобы получить более точные результаты
        </small>
      )}
      {(!isSearch || (isSearch && findSettings?.search)) && tabsList}
    </>
  )
}

export default CandidateGroupMenu
